import commonEnums from "@ui/Utils/commonEnums";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApi["data-engine"];

export const regenerateToken = async () => {
  try {
    const url =
      baseApiUrl +
      "c/" +
      commonEnums.models["data-engine"].tokens +
      "/regenerateToken";

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getStockFields = async () => {
  try {
    const url =
      baseApiUrl +
      "c/" +
      commonEnums.models["data-engine"].products +
      "/getStockFields";

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
