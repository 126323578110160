import React, { useEffect, useMemo, useState } from "react";

import BulkImageUpload from "./components/BulkImageUpload";
import BulkStockUpload from "./components/BulkStockUpload";
import APIKeyGenerator from "./components/APIKeyGenerator";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import commonEnums from "@ui/Utils/commonEnums";
import { Button } from "@material-ui/core/index";

const DeveloperDashboard = () => {
  const [selectedSection, setSelectedSection] = useState("APIKey");
  const [apiKey, setApiKey] = useState();
  const [data, setData] = useState({});

  const model = commonEnums.models["data-engine"].tokens;
  const menuItems = [
    { key: "APIKey", label: "X API Key" },
    { key: "BulkImageUpload", label: "Bulk Image Upload" },
    { key: "BulkStockUpload", label: "Bulk Stock Upload" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const tokenRes = await getSingleRecord(model, {
          filterColumns: {
            status: 1,
            code: 1,
            dateCreated: 1,
            dateModified: 1,
          },
        });

        setData({
          status: tokenRes?.status,
          code: tokenRes?.code,
          dateCreated: tokenRes?.dateCreated,
          dateModified: tokenRes?.dateModified,
        });
      } catch (err) {
        console.log(err);
      }
    })();

    return () => setData({});
  }, []);

  const renderComponent = useMemo(() => {
    if (selectedSection === "BulkImageUpload") return <BulkImageUpload />;
    if (selectedSection === "BulkStockUpload") return <BulkStockUpload />;
    return (
      <APIKeyGenerator
        apiKey={apiKey}
        setApiKey={setApiKey}
        data={data}
        setData={setData}
      />
    );
  }, [selectedSection, apiKey, data]);

  return (
    <div>
      <h1>🕊️ API</h1>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {menuItems.map((item) => (
          <Button
            style={{
              backgroundColor: item.key === selectedSection ? "orange" : "grey",
            }}
            key={item.key}
            onClick={() => setSelectedSection(item.key)}
            className={`px-4 py-2 rounded-md transition ${
              selectedSection === item.key
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {item.label}
          </Button>
        ))}
      </div>

      <div className="mt-4">{renderComponent}</div>
    </div>
  );
};

export default DeveloperDashboard;
