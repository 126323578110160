import React from "react";

// icons
import PrintIcon from "@mui/icons-material/Print";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import StarsIcon from "@mui/icons-material/Stars";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
// Components
import HomePage from "pages/HomePage/HomePage";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ProductDetail from "@ui/views/data-engine/Import/Products/Detail/Detail";
import CategoryDetail from "views/Category/Detail/Detail";

// utils
import enums from "helpers/enums";
import ImportMenu from "@ui/views/data-engine/Import/List/index";
import InventoryIcon from "@ui/assets/data-engine/assets/menuIcons/Inventory Management.png";
import reportsIcon from "@ui/assets/data-engine/assets/menuIcons/Reports.png";
import InventoryList from "views/Inventory/List/List";
import UsersList from "views/Users/List/List";
import UserDetail from "views/Users/Detail/Detail";
import DivisionDetail from "views/Divisions/Detail/Detail";
import DivisionsList from "views/Divisions/List/List";
import {
  isAdmin,
  isDivisionControlled,
  isSuperAdmin,
  selectedDivisionId,
  userDetails,
} from "@ui/Utils/helper";
import CollectionLineDetail from "views/Collection Lines/Detail/Detail";
import CustomersList from "@ui/views/data-engine/Customers/List/List";
import CustomerDetail from "@ui/views/data-engine/Customers/Detail/Detail";
import PrintemplatesList from "views/Printemplates/List/List";
import PrintemplatesDetail from "views/Printemplates/Detail/Detail";
import DivaList from "views/Diva/List/List";

import MasterData from "views/MasterData/index";
import DiamondPricingDetail from "views/DiamondPricing/Detail/Detail";
import SubCategoryDetail from "views/SubCategory/Detail/Detail";
import VendorCodeDetail from "@ui/views/vendor-portal/VendorCodes/Detail/Detail";
import PurityDetail from "views/Purity/Detail/Detail";
import GoldPricingDetail from "views/GoldPricing/Detail/Detail";
import TokenDetail from "@ui/views/data-engine/Tokens/Detail/Detail";
import commonEnums from "@ui/Utils/commonEnums";

const styles = {
  icon: { height: 30, width: 30 },
  titleStyle: {
    //  color: "#1d9bc2",
    // fontWeight: "bolder",
    // fontSize: "14px",
  },
};

const MenuIcon = ({ icon }) => {
  return <img src={icon} alt="ico" style={styles.icon} />;
};

export const primaryMenuRoutes = (screenAccess = false) => [
  {
    access: screenAccess || userDetails?.access?.dataHub,
    // createRoute: screenAccess || userDetails?.access?.dataHub,
    title: "Data Hub",
    titleStyle: styles.titleStyle,
    path: enums.models.dataEntry,
    hideMenuByDefault: true,
    icon: () => <SportsEsportsOutlinedIcon style={{ color: "#1d9bc2" }} />,
    selectedIcon: () => <SportsEsportsIcon style={{ color: "#1d9bc2" }} />,
    component: () =>
      screenAccess || userDetails?.access?.dataHub ? (
        <ImportMenu />
      ) : (
        <HomePage />
      ),
  },
  {
    access: screenAccess,
    //     createRoute: screenAccess,
    title: "Master Data",
    titleStyle: styles.titleStyle,
    path: enums.models.masterData,
    icon: () => <StarsIcon style={{ color: "#1d9bc2" }} />,
    component: () => (screenAccess ? <MasterData /> : <HomePage />),
  },
  {
    // only to create a route, not for Menu
    access: false,
    //     createRoute: screenAccess,
    path: enums.models.bulkPrint,
    hideMenuByDefault: true,
    component: () =>
      screenAccess || userDetails?.access?.dataHub ? (
        <ImportMenu />
      ) : (
        <HomePage />
      ),
  },
  {
    // only to create a route, not for Menu
    access: false,
    //     createRoute: screenAccess,
    path: enums.models.excelImport,
    hideMenuByDefault: true,
    component: () =>
      screenAccess || userDetails?.access?.dataHub ? (
        <ImportMenu />
      ) : (
        <HomePage />
      ),
  },
  {
    // only to create a route, not for Menu
    access: false,
    //     createRoute: screenAccess,
    path: enums.models.imageUpload,
    hideMenuByDefault: true,
    component: () =>
      screenAccess || userDetails?.access?.dataHub ? (
        <ImportMenu />
      ) : (
        <HomePage />
      ),
  },
  {
    access: screenAccess,
    //     createRoute: screenAccess,
    title: "Inventory",
    titleStyle: styles.titleStyle,
    hideMenuByDefault: true,
    path: enums.models.inventory,
    icon: () => <MenuIcon icon={InventoryIcon} />,
    component: () => (screenAccess ? <InventoryList /> : <HomePage />),
  },
  {
    access:
      screenAccess &&
      userDetails.client?.appConfig?.["data-engine"]?.divisionLevel?.[
        selectedDivisionId || "default"
      ]?.integrateWithDiva,
    // createRoute:
    //   screenAccess &&
    //   userDetails.client?.appConfig?.["data-engine"]?.divisionLevel?.[
    //     selectedDivisionId || "default"
    //   ]?.integrateWithDiva,
    title: "Diva",
    titleStyle: styles.titleStyle,
    path: enums.models.diva,
    icon: () => <CloudSyncIcon style={{ color: "#1d9bc2" }} />,
    component: () => (screenAccess ? <DivaList /> : <HomePage />),
  },
  // {
  //   access: screenAccess,
  //   title: "Master",
  //   path: enums.models.products,
  //   icon: () => <MenuIcon icon={masterIcon} />,
  //   component: () => <ProductsList />,
  // },
  // {
  //   access: screenAccess,
  //   title: "Reports",
  //   path: enums.models.products,
  //   icon: () => <MenuIcon icon={reportsIcon} />,
  //   component: () => <ProductsList />,
  // },
  {
    access: isDivisionControlled && screenAccess,
    // createRoute: isDivisionControlled && screenAccess,
    title: "Divisions",
    titleStyle: styles.titleStyle,
    path: enums.models.divisions,
    icon: () => <MenuIcon icon={reportsIcon} />,
    component: () => (screenAccess ? <DivisionsList /> : <HomePage />),
  },

  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Employees",
    titleStyle: styles.titleStyle,
    path: enums.models.iam.users,
    icon: () => <PersonAddAltIcon style={{ color: "#1d9bc2" }} />,
    component: () => (screenAccess ? <UsersList /> : <HomePage />),
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Customers",
    titleStyle: styles.titleStyle,
    path: enums.models.users,
    icon: () => <SupportAgentIcon style={{ color: "#1d9bc2" }} />,
    component: () => (screenAccess ? <CustomersList /> : <HomePage />),
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "TagTemplates",
    titleStyle: styles.titleStyle,
    path: enums.models.printerTemplates,
    icon: () => <PrintIcon style={{ color: "#1d9bc2" }} />,
    component: () => (screenAccess ? <PrintemplatesList /> : <HomePage />),
  },
  {
    access:
      screenAccess &&
      (userDetails.client?.appModules || []).some(
        (app) => app.key === "x-api-key"
      ),
    // createRoute:
    //   screenAccess &&
    //   (userDetails.client?.appModules || []).some(
    //     (app) => app.key === "x-api-key"
    //   ),
    title: "# dev",
    titleStyle: styles.titleStyle,
    path: commonEnums.models["data-engine"].tokens,
    icon: () => <DeveloperBoardIcon style={{ color: "#1d9bc2" }} />,
    component: () => (isAdmin || isSuperAdmin ? <TokenDetail /> : <HomePage />),
  },
  // {
  //   access: screenAccess,
  //   title: "Data Grid",
  //   path: "test/datagrid",
  //   icon: () => <MenuIcon icon={reportsIcon} />,
  //   component: () => <DataGridList />,
  // },
];

export const secondaryMenuRoutes = (screenAccess) => [
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Category",
    path: enums.models.category + "/:code",
    component: () => <CategoryDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "SubCategory",
    path: enums.models.subCategory + "/:code",
    component: () => <SubCategoryDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Vendor Codes",
    path: enums.models["vendor-portal"].vendorCodes + "/:code",
    component: () => <VendorCodeDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Purity",
    path: enums.models.purity + "/:code",
    component: () => <PurityDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Gold Labor Pricing",
    path: enums.models.goldPricing + "/:code",
    component: () => <GoldPricingDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Collection Lines",
    path: enums.models.collectionLines + "/:code",
    component: () => <CollectionLineDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Diamond Pricing",
    path: enums.models.diamondPricing + "/:code",
    component: () => (screenAccess ? <DiamondPricingDetail /> : <HomePage />),
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Products",
    path: enums.models.products + "/:code",
    component: () => <ProductDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Divisions",
    path: enums.models.divisions + "/:code",
    component: () => <DivisionDetail />,
  },

  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Users",
    path: enums.models.iam.users + "/:code",
    component: () => <UserDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    title: "Customers",
    path: enums.models.users + "/:code",
    component: () => <CustomerDetail />,
  },
  {
    access: screenAccess,
    // createRoute: screenAccess,
    path: enums.models.printerTemplates + "/:code",
    component: () => <PrintemplatesDetail />,
  },
];
