import React, { useState, useEffect } from "react";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AirplanemodeActiveOutlinedIcon from "@mui/icons-material/AirplanemodeActiveOutlined";
import AirplanemodeInactiveOutlinedIcon from "@mui/icons-material/AirplanemodeInactiveOutlined";
import { Button } from "@material-ui/core/index";
import { formatDateToRead } from "@ui/Utils/helper";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { regenerateToken, updateRecord } from "../../api";
import commonEnums from "@ui/Utils/commonEnums";
import Emitter from "@ui/Utils/CustomEventEmitter";
import APIKey from "./APIKey";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  Emitter.emit("alert_success", "Copied to clipboard!");
};

const APIKeyGenerator = ({ apiKey, setApiKey, data, setData }) => {
  const [loading, setLoading] = useState(false);

  const model = commonEnums.models["data-engine"].tokens;

  // Generate New API Key
  const generateApiKey = async () => {
    try {
      let continueFlag = false;
      if (data.code) {
        if (
          window.confirm(
            "Generating a new API key will deactivate the previous one. Proceed?"
          )
        )
          continueFlag = true;
      } else {
        continueFlag = true;
      }

      if (continueFlag) {
        setLoading("Loading...");
        const tokenRes = await regenerateToken();
        setApiKey(tokenRes?.apiKey);
        setData({
          ...data,
          dateCreated: tokenRes.dateCreated,
          dateModified: tokenRes.dateModified,
          status: tokenRes.status,
        });
        Emitter.emit("alert_success", "API Key Generated!");
        setLoading("Token Generated");
      }
    } catch (_) {
      setLoading("Too Many Requests");
      Emitter.emit("alert_error", "Too Many Requests, try again later.");
    }
  };

  const handleActivation = async (activeStatus) => {
    try {
      const res = await updateRecord(model, {
        code: data.code,
        status: activeStatus,
      });

      setData({
        ...data,
        status: res.status,
        dateCreated: res.dateCreated,
        dateModified: res.dateModified,
      });

      Emitter.emit(
        "alert_success",
        `API Key ${activeStatus ? "Activated" : "DeActivated"}`
      );
    } catch (_) {
      Emitter.emit("alert_error", "Something went wrong!");
    }
  };

  return (
    <>
      <MainSection title="" style={{ marginTop: "12px" }}>
        {data.code ? (
          <h2 style={{ color: data.status ? "black" : "grey" }}>
            Last Generated On:{" "}
            {data.dateModified
              ? formatDateToRead(data.dateModified, globalDateTimeFormat.read)
              : formatDateToRead(data.dateCreated, globalDateTimeFormat.read)}
            <Button variant="destructive" size="icon">
              {data.code ? (
                data.status ? (
                  <MyHoverIcons
                    onClick={() => handleActivation(false)}
                    muiIcon={
                      <AirplanemodeActiveOutlinedIcon
                        style={{ color: "green" }}
                      />
                    }
                    muiIconHover={
                      <AirplanemodeInactiveOutlinedIcon
                        style={{ color: "red" }}
                      />
                    }
                    tooltip={"DeActivate"}
                  />
                ) : (
                  <MyHoverIcons
                    onClick={() => handleActivation(true)}
                    muiIcon={
                      <AirplanemodeInactiveOutlinedIcon
                        style={{ color: "red" }}
                      />
                    }
                    muiIconHover={
                      <AirplanemodeActiveOutlinedIcon
                        style={{ color: "green" }}
                      />
                    }
                    tooltip={"Activate"}
                  />
                )
              ) : null}
            </Button>
          </h2>
        ) : (
          ""
        )}

        <Button
          onClick={generateApiKey}
          style={{
            backgroundColor: loading ? "grey" : "green",
            color: "white",
          }}
          disabled={loading}
          className="mb-4"
        >
          {loading ? loading : "Generate API Key"}
        </Button>

        <div className="flex items-center justify-between bg-gray-700 p-2 rounded-md mb-2">
          <span className="text-sm">
            {apiKey ? (
              <>
                <pre style={{ color: data.status ? "black" : "grey" }}>
                  x-api-key: {apiKey}
                </pre>
                <pre style={{ color: "red" }}>
                  This is a secret token, keep it safe, it won't be visible
                  again.
                </pre>
              </>
            ) : (
              ""
            )}
          </span>
          <div className="flex gap-2">
            {apiKey ? (
              <Button variant="ghost" size="icon">
                <MyHoverIcons
                  muiIcon={<FileCopyOutlinedIcon />}
                  muiIconHover={<FileCopyIcon />}
                  tooltip={"Copy"}
                  onClick={() => copyToClipboard(apiKey)}
                />
              </Button>
            ) : null}
          </div>
        </div>
      </MainSection>

      <APIKey />
    </>
  );
};

export default APIKeyGenerator;
