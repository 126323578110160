import { isDivisionControlled } from "@ui/Utils/helper";
import React from "react";

const APIKey = () => {
  return (
    <div className="p-4 border rounded-md bg-gray-100">
      <h2 className="text-xl font-bold">API Key</h2>
      <p className="mt-2">
        An API key is a unique identifier used to authenticate requests made to
        the system. It acts as a security token that allows authorized users to
        interact with the API while ensuring data integrity and access control.
      </p>
      {isDivisionControlled ? (
        <p className="mt-2">
          Each API key is division-specific, meaning that an API key generated
          for Division A can only be used to create, update, or retrieve records
          within Division A. Similarly, an API key for Division B will operate
          exclusively within that division.
        </p>
      ) : null}
      <p className="mt-2">
        To interact with the API, you must include the API key in the request
        headers for authentication. Without a valid API key, API requests will
        be rejected.
      </p>

      <h3 className="mt-4 font-semibold">Token Management</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <strong>Token Deactivation:</strong> If the API key is deactivated,
          any API requests using that token will stop working.
        </li>
        <li>
          <strong>Token Visibility:</strong> Once generated, the API key will
          not be displayed again in the UI, so it must be stored securely.
        </li>
        <li>
          <strong>Regenerating the API Key:</strong> You can regenerate a new
          API key if needed. However, once regenerated, the old API key will no
          longer be valid.
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Security Considerations</h3>
      <p className="mt-2">
        If you lose your API key or suspect unauthorized access, anyone with
        this key will be able to insert records into your Data Engine. To revoke
        access, you can either regenerate the key or deactivate it from the{" "}
        <strong>#dev</strong> screen.
      </p>
    </div>
  );
};

export default APIKey;
