import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist/index";
import { Grid, Box } from "@mui/material";
import Card from "@ui/components/Card";
import { getProductImageUri, imageExpired } from "@ui/Utils/helper";
import enums from "helpers/enums";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";

const scrollableStyle = {
  display: "flex",
  overflowX: "auto",
  padding: "10px 0",
  width: "100%",
};

const imageStyle = {
  marginRight: "10px",
  cursor: "pointer",
  border: "2px solid transparent",
  borderRadius: "5px",
  transition: "border-color 0.2s",
  height: "240px",
  width: "240px",
  padding: "12px",
  backgroundColor: "white",
  borderColor: "white",
};

const selectedImageStyle = {
  ...imageStyle,
  borderColor: enums.colorTheme.primary,
};

const SingleLineImageList = ({ images, onSelect, selectedIndex }) => (
  <Box style={scrollableStyle}>
    {images.map((image, index) => (
      <img
        key={index}
        src={`data:image/png;base64,${image.signedUrl}`}
        alt={`Cropped ${index}`}
        style={index === selectedIndex ? selectedImageStyle : imageStyle}
        onClick={() => onSelect(index)}
      />
    ))}
  </Box>
);

const Classify = ({ classification = {}, setShowTopPaginationArrow }) => {
  const [currentCropIndex, setCurrentCropIndex] = useState(0);
  const croppedImages = Object.keys(classification).map((classifiedIndex) => ({
    signedUrl: classification[classifiedIndex].croppedImage,
  }));

  const handleImageSelect = (index) => {
    setCurrentCropIndex(index);
  };

  useEffect(() => {
    if (
      classification[currentCropIndex]?.matches?.length > 8 &&
      setShowTopPaginationArrow
    ) {
      setShowTopPaginationArrow(true);
    } else {
      setShowTopPaginationArrow(false);
    }
  }, [classification[currentCropIndex]?.matches]);

  return (
    <Grid container spacing={2}>
      {croppedImages.find((e) => e?.signedUrl) ? (
        <Grid item xs={12}>
          <SingleLineImageList
            images={croppedImages}
            onSelect={handleImageSelect}
            selectedIndex={currentCropIndex}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} container>
        <DisplayCards
          products={classification[currentCropIndex]?.matches || []}
        />
      </Grid>
    </Grid>
  );
};

const DisplayCards = ({ products }) => {
  const navigate = useNavigate();

  return products.length ? (
    products.map((prod) => {
      return (
        <Grid item xs={3} key={prod._id}>
          <Card
            style={{
              padding: "8px",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "16px",
            }}
          >
            <div>
              <DisplayImage prod={prod} />
            </div>
            <div
              onClick={() =>
                prod.code &&
                navigate("/" + enums.models.products + "/" + prod.code)
              }
              style={{ cursor: "pointer" }}
            >
              <div style={{ fontSize: 16 }}>
                <b>{prod.styleNo}</b>
              </div>
              {prod.grossWeight ? (
                <div style={{ fontSize: 12 }}>
                  Gross Weight: <b>{Number(prod.grossWeight).toFixed(3)} g</b>
                </div>
              ) : null}
              {prod.netWeight ? (
                <div style={{ fontSize: 12 }}>
                  Net Weight: <b>{Number(prod.netWeight).toFixed(3)} g</b>
                </div>
              ) : null}
            </div>
          </Card>
        </Grid>
      );
    })
  ) : (
    <Grid item xs={12}>
      <center>
        <pre>
          <h4 style={{ letterSpacing: 1 }}>No Jewel Found</h4>
        </pre>
      </center>
    </Grid>
  );
};

const DisplayImage = ({ prod }) => {
  const [openPreview, setOpenPreview] = useState();
  const imageUri = getProductImageUri(prod);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {openPreview?.code && openPreview?.code === prod?.code ? (
        <ProductDetailPopup
          product={openPreview}
          onClose={() => setOpenPreview()}
          open={true}
        />
      ) : null}

      <img
        style={{
          height: 100,
          width: 100,
          zIndex: isHovered ? 100 : 99,
          position: "relative",
          objectFit: "contain",
          cursor: "grab",
          userSelect: "none",
          // transition: "transform 3s ease",
          // "&:hover": { transform: "scale(4.3)" }, // Adjust scale as needed
          transition: "transform 3s ease",
          transform: isHovered ? "scale(4.3)" : "scale(1)",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setOpenPreview(prod)}
        src={imageUri}
        onError={(event) => {
          event.target.src = imageExpired;
        }}
      />
    </>
  );
};

export default Classify;
