import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider, RequireAuth } from "@ui/components/AuthProvider";
import SnackbarComponent from "@ui/components/SnackbarComponent";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import OTP from "./@ui/Auth/OTP";
import ResetPassword from "./@ui/Auth/ResetPassword";
import SendOTP from "./@ui/Auth/SendOTP";
import Signin from "./@ui/Auth/Signin";
import SignUp from "./@ui/Auth/SignUp";
import LandingPage from "./@ui/components/LandingPage/LandingPage";
import Loader from "@ui/MuiComponents/Loader/Loader";
import ResponsiveDrawer from "@ui/MuiComponents/Drawer/ResponsiveDrawer";
import { primaryMenuRoutes, secondaryMenuRoutes } from "routes";
import enums from "helpers/enums";
import DivisionControl from "@ui/components/LandingPage/DivisionControl";
import { userDetails, userHasAccess } from "@ui/Utils/helper";

const theme = createTheme({
  typography: {
    fontFamily: "Advent Pro",
  },
  direction: "ltr",
});

const GlobalStyle = createGlobalStyle`
   body {
    background: #F5F5F5;
  font-family: 'Advent Pro', sans-serif; 
  min-height: 100vh;
  padding-inline: 2vh;overflow:hidden;
  }
 
`;

function App() {
  const [userInfo, setUserInfo] = useState({});
  const [mainMenus, setMainMenus] = useState(
    primaryMenuRoutes(userHasAccess().screen) || []
  );
  const [detailMenus, setDetailMenus] = useState(
    secondaryMenuRoutes(userHasAccess().screen) || []
  );

  useEffect(() => {
    setMainMenus(primaryMenuRoutes(userHasAccess().screen) || []);
    setDetailMenus(secondaryMenuRoutes(userHasAccess().screen) || []);
  }, [userDetails]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarComponent />
          <GlobalStyle />
          <Loader />

          <HashRouter>
            <DivisionControl userInfo={userInfo} />
            <ResponsiveDrawer
              appName={"Data Engine"}
              primaryMenus={mainMenus}
              userInfo={userInfo}
            >
              <Routes>
                <Route
                  path="*"
                  element={
                    <LandingPage
                      userInfo={userInfo}
                      homePath={enums.models.inventory}
                    />
                  }
                />
                <Route
                  path="*/:home"
                  element={
                    <LandingPage
                      userInfo={userInfo}
                      homePath={enums.models.inventory}
                    />
                  }
                />
                <Route
                  path="signIn"
                  element={
                    <Signin
                      title="Data Engine Admin Panel"
                      message="It's all Maths and Data"
                      iamLogin={true}
                      clientSelect="appConfig.data-engine"
                      userInfo={userInfo}
                      userSelect={"access"}
                      setUserInfo={setUserInfo}
                    />
                  }
                  exact
                />
                <Route path="signUp" element={<SignUp />} exact />
                <Route path="otp" element={<OTP />} exact />
                <Route path="sendOtp" element={<SendOTP />} exact />
                <Route path="resetPassword" element={<ResetPassword />} exact />

                {[...mainMenus, ...detailMenus].map((route, i) => {
                  return (
                    <Route
                      key={i}
                      path={route.path}
                      element={<RequireAuth>{route.component()}</RequireAuth>}
                      exact
                    />
                  );
                })}
              </Routes>
            </ResponsiveDrawer>
          </HashRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
