import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getStockFields } from "../../api";
import { Card, CardContent } from "@material-ui/core/index";
import commonConfig from "config/commonConfig";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { copyToClipboard } from "./APIKeyGenerator";
import { userDetails } from "@ui/Utils/helper";

const BulkStockUpload = () => {
  const [api, setApi] = useState({
    name: "Bulk Stock Upload",
    description: "Create or Update Products data in Bulk",
    endpoint: `${commonConfig.baseApiUrl}designs/bulkStockUpsert`,
    method: "POST",
    contentType: "application/json",
    validation: "MAX 5000 stocks per API chunk",
    requestBody: {
      replaceStocks: `Boolean: true to create & update, false to only create`,
      data: [{}],
      diamondDetails: [],
    },
    curl: `curl --location '${commonConfig.baseApiUrl}designs/bulkStockUpsert' \
                --header 'x-api-key;' \
                --header 'Content-Type: application/json' \
                --data '{
                  "replaceStocks": false,
                  "data": [],
                  "diamondDetails": []
                }'`,
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getStockFields();

        const stockFields = {},
          diaFields = {};
        (res.stockLevelFields || []).map(
          (e) => (stockFields[e.label] = e.dataType)
        );
        res.diamondFields.map((e) => (diaFields[e.label] = e.dataType));

        const tempApi = _.cloneDeep(api);

        tempApi.curl = tempApi.curl.replace(
          `"data": []`,
          `"data": [${JSON.stringify(stockFields)}]`
        );

        if (res.diamondFields?.length) {
          tempApi.curl = tempApi.curl.replace(
            `"diamondDetails": []`,
            `"diamondDetails": [${JSON.stringify(diaFields)}]`
          );
        }

        tempApi.requestBody = {
          ...tempApi.requestBody,
          data: [stockFields],
          diamondDetails: res.diamondFields?.length ? [diaFields] : undefined,
        };

        setApi(tempApi);
      } catch (_) {}
    })();

    return () => setApi({});
  }, []);

  return (
    <div className="p-4 border rounded-md bg-gray-100">
      <h2 className="text-xl font-bold">Bulk Stock Upload</h2>

      <p className="mt-2">
        The Bulk Stock Upload API allows you to create or update product stock
        data in bulk.
      </p>

      <Card
        style={{ marginBottom: "12px" }}
        className="mb-6 border border-gray-700 bg-gray-800 p-4 rounded-lg"
      >
        <CardContent>
          {/* <h2 className="text-xl font-semibold text-blue-400">{api.name}</h2>
          <p className="text-gray-300">{api.description}</p> */}

          <div className="mt-3 flex items-center">
            <span className="font-medium text-gray-400">🔗 Endpoint:</span>
            <pre className="ml-2 bg-gray-700 text-gray-300 p-2 rounded-lg">
              {api.endpoint}{" "}
              <MyHoverIcons
                muiIcon={<FileCopyOutlinedIcon />}
                muiIconHover={<FileCopyIcon />}
                tooltip={"Copy"}
                onClick={() => copyToClipboard(api.endpoint)}
              />
            </pre>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">🔴 Use Case: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.validation}
            </span>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">🟢 Method: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.method}
            </span>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">𝌞 Content Type: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.contentType}
            </span>
          </div>

          {/* Request Body */}
          <div className="mt-3">
            <div>
              <span className="font-medium text-gray-400">
                📌 Request Body:
              </span>
              <pre className="mt-2 bg-gray-700 text-gray-300 p-3 rounded-lg text-sm">
                {JSON.stringify(api.requestBody, null, 2)}
              </pre>
            </div>
          </div>

          <div>
            <div>
              <span className="font-medium text-gray-400">🕸️ CURL:</span>

              <div style={{ wordBreak: "break-all" }}>{api.curl}</div>
              <MyHoverIcons
                muiIcon={<FileCopyOutlinedIcon />}
                muiIconHover={<FileCopyIcon />}
                tooltip={"Copy CURL"}
                onClick={() => copyToClipboard(api.curl)}
              />
            </div>
          </div>

          {/* <div>
              <div>
                <span className="font-medium text-gray-400">🕸️ Response:</span>

                <pre className="mt-2 bg-gray-700 text-gray-300 p-3 rounded-lg text-sm">
                  {JSON.stringify(api.response, null, 2)}
                </pre>
              </div>
            </div> */}
        </CardContent>
      </Card>

      <h3 className="mt-4 font-semibold">Important Rules & Considerations</h3>
      <ul className="mt-2 list-disc pl-4">
        {userDetails.client.premiumClient?.unlimitedBucketStorage ? (
          <li>You can upload either products first or images first.</li>
        ) : (
          <li>
            Products must be uploaded before images upload so that images will
            get Synced with their products.
          </li>
        )}
        <li>
          String fields should be sent as strings, and number fields as numbers.
        </li>
        <li>
          Stock data cannot be updated unless <code>replaceStocks: true</code>{" "}
          is sent.
        </li>
        <li>Only "Staged" or "In Inventory" products can be updated.</li>
        <li>Products in "In Approval" or "Issued" status will be ignored.</li>
        <li>
          Empty values will replace existing values by removing its key value
          pair.
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Stock Data Formatting Guidelines</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <strong>StyleNo</strong>: Design Number
        </li>
        <li>
          <strong>SKU</strong>: Unique ID for each stock
        </li>
        <li>
          <strong>Purity</strong>: Must include KT (e.g., "22 KT").
        </li>
        <li>
          <strong>Enum Fields</strong> (Fixed Values):
          <ul className="list-disc pl-6">
            <li>Color Options: "R", "W", "WR", "Y", "YR", "YW", "YRW"</li>
            <li>Setting Options: "Open", "Close", "Open and Close"</li>
            <li>Screw Options: "Bombay", "South"</li>
          </ul>
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">API Details</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          Method: <strong>POST</strong>
        </li>
        <li>
          Supports bulk product stock creation and updates (up to 5,000 stocks
          per request).
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Headers</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <code>x-api-key</code>: Required for authentication
        </li>
        <li>
          <code>Content-Type</code>: <code>application/json</code>
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Request Body Format Example</h3>
      <pre className="bg-gray-200 p-2 rounded-md text-sm overflow-auto">
        {`
{
  "replaceStocks": true,
  "data": [
    {
      "SKU": "SKU12345",
      "Style No": "D1234",
      "Category": "Rings",
      "Sub Category": "Engagement Rings",
      "Collection Line": "Luxury",
      "Purity": "22 KT",
      "Color": "Y",
      "Gross Weight": 15.3,
      "Vendor Code": "V001",
      "Zone": "North",
      "Pieces": 1
    }
  ]
}
        `}
      </pre>

      <h3 className="mt-4 font-semibold">Response Codes</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <strong>200</strong>: Success
        </li>
        <li>
          <strong>400</strong>: Validation error if stocks exceed 5,000.
        </li>
        <li>
          <strong>429</strong>: Rate limit exceeded (wait 3 minutes before
          retrying).
        </li>
        <li>
          <strong>500</strong>: Internal server error. Contact Customer Support.
        </li>
      </ul>

      <h2>Response</h2>

      <pre>{`
      {
        "success": true, // Indicates that the request was successfully processed

        "data": {
            "created": 0, // Number of new products that were successfully created (0 in this case)
            "updated": 0, // Number of existing products that were successfully updated (0 in this case)
            "ignored": 1, // Number of products that were skipped and not processed at all
            "failed": 1, // Number of products that failed to be created or updated

            "invalidData": { // Contains details of invalid SKUs and the reasons for their failure
            "gold": { // Invalid SKUs related to gold products
                "0": { // First invalid product entry
                "sku": "", // SKU is missing (empty string)
                "columns": [
                    "SKU" // The error is due to a missing SKU field
                ]
                },
                "1": { // Second invalid product entry
                "sku": "String", // SKU provided as "String"
                "columns": [
                    "SKU Exists with Staged Stock Status" // The reason for failure: SKU already exists with a "Staged" stock status
                ]
                }
            },

            "diamond": {
                "0": { // 0th index  of diamondDetails is having invalid columns given in columns array
                        "sku": "String",
                        "columns": [
                            "Total Carat",
                            "Shape"
                        ]
                    }
                } 
            }
        },

        "message": "Bulk Stock Import Successful" // General message indicating the process completed
      }
      `}</pre>
    </div>
  );
};

export default BulkStockUpload;
